<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CIERRE REPLICAS</b></span></v-toolbar>
      <v-card-text class="pt-0" v-for="(itemEsc, indexEsc) in dataReplica" :key="indexEsc">
        <div v-if="dataReplica[indexEsc].fechacerrado !== null">
          <span :style="[{'color': colores.primario}, {'font-size': 10 }]">{{dataReplica[indexEsc].fechacerrado}}</span>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <texto2 :colores="colores" :titulo="'PRÓRROGA'" :descripcion="dataReplica[indexEsc].prorroga.descripcion"></texto2>
            </v-col>
          </v-row>
          <br>
          <b>RESUMEN RESPUESTA RÉPLICA</b>
          <v-row no-gutters>
            <v-col cols="12">
              <v-card-text class="pa-0" style="white-space:pre-wrap;">
                  <div >{{ dataReplica[indexEsc].respuesta }}</div>
              </v-card-text>
            </v-col>
          </v-row>
          <br>
          <v-divider></v-divider>
          <br>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <b>ARCHIVOS DE RESPUESTA FINAL</b>
            </v-col>
            <v-col cols="12" md="6" >
              <b>ARCHIVOS DE ANEXOS</b>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <div v-for="(itemFile, i) in registro.gescierrefiles" :key="i">
                <verArchivos v-if="itemFile.tipo_id===1 && itemFile.replica_id === dataReplica[indexEsc].id" :colores="colores" :tipo="'CIERREFINAL'" :item="itemFile"></verArchivos>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div v-for="(itemFile, i) in registro.gescierrefiles" :key="i">
                <verArchivos v-if="itemFile.tipo_id===2 && itemFile.replica_id === dataReplica[indexEsc].id" :colores="colores" :tipo="'CIERREFINAL'" :item="itemFile"></verArchivos>
              </div>
            </v-col>
          </v-row>
        </div>
        <br>
        <v-divider></v-divider>
        <br>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
export default {
  name: 'crm_lectura_cierreComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    verArchivos: () => import('@/components/widgets/ver_archivosComponent.vue')
  },
  props: ['colores', 'registro', 'dataReplica'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
